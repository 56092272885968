import React from "react";

function Header() {
  return (
    <div>
      <div id={"headerId"} className="sticky-info-wrapper">
        <div className="sticky-info-flex-child">
          <div className="container-lg sticky-info">
            <div
              data-w-id="afdb5a19-3eb4-3fa5-6cf7-bb479a2cf917"
              className="good-news-text"
            >
              Welcome to SURF Reward - Biggest PreSale 2023! What do you think?
              100x or more!?
            </div>
          </div>
        </div>
      </div>
      <div
        id={"headerIdTwo"}
        data-collapse="medium"
        data-animation="default"
        data-duration={0}
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar w-nav"
      >
        <div
          className="w-layout-grid navbar-list "
          style={{ gridTemplateColumns: "auto auto auto" }}
        >
          <a
            href="#buynow"
            aria-current="page"
            className="brand w-nav-brand w--current"
          >
            <img
              src="images/Logo2_transparent_backgroundBG_.png"
              loading="lazy"
              alt=""
              className="image-contain"
            />
          </a>
          <div className="dropdown ml-auto pc-style">
            <button
              // onClick="switchNetwork()"
              id="selectNetwork1"
              className="button-animated small w-inline-block dropbtn"
            >
              Ethereum Blockchain
            </button>
            <div id="myDropdown1" className="dropdown-content">
              <div
                className="list-link"
                // onClick="selectChain(2)"
              >
                Ethereum Blockchain
              </div>
              <div
                className="list-link "
                // onClick="selectChain(1)"
              >
                Binance Smart Chain (BSC)
              </div>
            </div>
          </div>
          <div className="other-links w-nav-menu">
            <nav className="header_navigation">
              <a href="#about">What is SURF?</a>
              <a href="#how-it-works">How it works</a>
              <a href="#specials">Bonus Specials</a>
              <a href="#roadmap">Roadmap</a>
              <a href="#team">Team</a>
              <a href="#contact-us">Contact</a>
            </nav>
            <a href="#">Jobs</a>
            {/* <a href="jobs.html">Jobs</a> */}
            <a href="https://docs.surfreward.io/" target="_blank">
              Demo
            </a>
          </div>
          <div
            id="w-node-_90910534-8503-0140-553b-dd5cfa0cbf37-fa0cbf10"
            className="navbar-controls"
          >
            <div className="hide-mobile">
              <div className="dropdown mobile-style">
                <a href="https://buy.surfreward.io/" target="_blank" />
                <button className="button-animated small w-inline-block dropbtn">
                  <a href="https://buy.surfreward.io/" target="_blank">
                    Buy now
                  </a>
                  <div id="myDropdown2" className="dropdown-content">
                    <div
                      className="list-link"
                      // onClick="selectChain(1)"
                    >
                      Binance Smart Chain (BSC)
                    </div>
                    <div
                      className="list-link"
                      // onClick="selectChain(2)"
                    >
                      Ethereum network
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div
              data-w-id="90910534-8503-0140-553b-dd5cfa0cbf3d"
              className="menu-button w-nav-button"
            >
              <div className="menu-button-wrap">
                <div className="menu-open">drag_handle</div>
                <div className="menu-close">close</div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-shadow" />
      </div>
    </div>
  );
}

export default Header;
