import React from 'react'

function About() {
    return (
        <section id="about" className="wrapper-parent">
            <div className="bg-pattern-wrap centered">
                <img
                    src="./images/bg-shape-08.png"
                    loading="lazy"
                    sizes="100vw"
                    alt=""
                    className="bg-pattern-image"
                />
            </div>
            <div className="container-lg" style={{ zIndex: 1 }}>
                <div className="w-layout-grid side-by-side-list">
                    <div className="w-layout-grid side-by-side-1-to-1">
                        <div id="w-node-_9058f2c5-f3e6-871c-30f0-4ef174693efa-052a211e">
                            <div className="margin-bottom margin-2xl">
                                <div className="max-width-xl">
                                    <div className="margin-bottom margin-xl">
                                        <div className="w-layout-grid caption-holder">
                                            <div className="w-layout-grid row-sm">
                                                <div className="caption opacity-50">01</div>
                                                <div className="caption">· Surf reward</div>
                                            </div>
                                            <div
                                                id="w-node-_9058f2c5-f3e6-871c-30f0-4ef174693f04-052a211e"
                                                className="line-holder"
                                            >
                                                <div
                                                    id="w-node-_9058f2c5-f3e6-871c-30f0-4ef174693f05-052a211e"
                                                    className="line-animated"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-layout-grid row-md">
                                        <div id="w-node-e593da4f-f3ce-b5c2-5ec3-60a6b3334602-052a211e">
                                            <img
                                                src="/images/Gruppe-7.svg"
                                                loading="lazy"
                                                alt=""
                                                className="image-12"
                                            />
                                        </div>
                                        <div id="w-node-_9058f2c5-f3e6-871c-30f0-4ef174693f09-052a211e">
                                            <div className="margin-bottom margin-base">
                                                <h2 className="font-title">
                                                    What is{" "}
                                                    <span className="text-gradient__blue">SURF Reward?</span>
                                                </h2>
                                            </div>
                                            <div className="text-lg _48ch">
                                                SURF Reward offers an extension for all major browsers. It
                                                removes a large part of the advertising on the Internet and
                                                exchanges individual advertising spaces with its own
                                                advertising partners. From now on, the profit goes not to
                                                the webmaster, but to you!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="w-node-_9058f2c5-f3e6-871c-30f0-4ef174693f38-052a211e"
                            className="image-wrapper-3-4 rounded-xs fade-in-on-scroll"
                        >
                            <img
                                src="/images/fade-bg-blue.svg"
                                loading="lazy"
                                alt=""
                                className="image-background"
                            />
                            <img
                                src="./images/about.jpg"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 70vw, (max-width: 991px) 450px, 600px"
                                alt=""
                                className="image-bg-contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default About