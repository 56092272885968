import React from 'react'

function Team() {
    return (
        <section id="team">
            <div className="team">
                <div className="container-lg v">
                    <div className="margin-bottom margin-6xl">
                        <div className="margin-bottom margin-xl">
                            <div className="w-layout-grid caption-holder-center">
                                <div
                                    id="w-node-fd3f082f-5921-0764-ac6a-4c9281b0952e-052a211e"
                                    className="line-holder invert"
                                >
                                    <div
                                        id="w-node-fd3f082f-5921-0764-ac6a-4c9281b0952f-052a211e"
                                        className="line-animated"
                                    />
                                </div>
                                <div className="w-layout-grid row-sm">
                                    <div className="caption opacity-50">06</div>
                                    <div className="caption">team</div>
                                </div>
                                <div
                                    id="w-node-fd3f082f-5921-0764-ac6a-4c9281b09535-052a211e"
                                    className="line-holder"
                                >
                                    <div
                                        id="w-node-fd3f082f-5921-0764-ac6a-4c9281b09536-052a211e"
                                        className="line-animated"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="section-video bg-tile-top-lg bg-neutral-600 _1-px">
                            <div className="content-centered">
                                <h2 className="title-xl _12ch">
                                    Team of
                                    <br />
                                    <span className="text-gradient__pink-purple">SURF Reward</span>
                                </h2>
                            </div>
                        </div>
                        <div className="w-layout-grid _2x-grid fade-in-on-scroll">
                            <div
                                id="w-node-fd3f082f-5921-0764-ac6a-4c9281b0953e-052a211e"
                                className="rounded-xs bg-neutral-800"
                            >
                                <div className="padding-lg space-between more-pad">
                                    <div className="bg-pattern-wrap centered team">
                                        <img
                                            src="images/Gruppe-62.png"
                                            loading="lazy"
                                            sizes="(max-width: 479px) 90vw, (max-width: 767px) 88vw, (max-width: 991px) 90vw, 89vw"
                                            // srcSet="images/Gruppe-62-p-500.png 500w, images/Gruppe-62-p-800.png 800w, images/Gruppe-62-p-1080.png 1080w, images/Gruppe-62.png 1355w"
                                            alt=""
                                            className="bg-pattern-image cover team"
                                        />
                                    </div>
                                    <p className="paragraph pad-both">
                                        Mia (Marketing - Acquisition of Advertiser &amp; Cooperation
                                        Partners), Moritz (Intern in the Company), Kishokanth
                                        (WEB3-Applications Developer), Nina (Marketing - Head of
                                        Marketing), Viktor (Network professional), Peter (Translator),
                                        Anna (Backoffice - Service assistant), Till (Full Stack
                                        Blockchain Developer), Armin (Founder), Andreas (Adviser),
                                        Tobias (System Administrator), Lisa (Supporter), Erdin
                                        (Technician), Klara (Supporter), Oliver (Full Stack Frontend
                                        Developer), Natalia (Press inquiries), Katharina (Backoffice),
                                        Sami (Project Manager)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="what-we-do">
                        <div className="container-xl">
                            <div className="ber-uns-wrapper">
                                <div className="shift-image-wrap rounded-xs fade-in-on-scroll">
                                    <div className="image-wrapper-4-3 o">
                                        <img
                                            src="images/_KOE0600.png"
                                            loading="lazy"
                                            data-w-id="24b870f7-7128-be8e-cbf7-4466e46856d5"
                                            sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 32vw"
                                            // srcSet="images/_KOE0600-p-500.png 500w, images/_KOE0600.png 665w"
                                            alt=""
                                            className="image-background"
                                        />
                                    </div>
                                </div>
                                <div className="shift-body fade-in-on-scroll">
                                    <h2 className="title-sm">About us</h2>
                                    <div className="text-lg">
                                        SURF Reward is a new project of Abelius Capital AG.
                                        <br />
                                        <br /> Abelius Capital AG is a public limited company founded in
                                        2019 under German law with headquarters in Düsseldorf, Germany.
                                        So far the focus has been on investing in global and large scale
                                        real estate projects. SURF Reward is their new (blockchain)
                                        project for which they have assembled a large and strong team.
                                        All employees are local, which allows for direct communication
                                        and cooperation.
                                        <a
                                            href="team.html"
                                            className="button-animated small w-inline-block"
                                        >
                                            <div className="button-label">Check out the team</div>
                                            <div className="button-hover-background" />
                                        </a>
                                        <div className="bg-pattern-wrap" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-xl">
                            <p />
                        </div>
                        <div className="container-lg">
                            <div className="w-layout-grid side-by-side-list">
                                <div className="w-layout-grid side-by-side-1-to-1">
                                    <div
                                        id="w-node-_4d36ff4a-87c5-213f-644b-68423367589f-052a211e"
                                        className="image-wrapper-3-4-2 rounded-xs fade-in-on-scroll team armin"
                                    >
                                        <img
                                            src="images/_KOE0728.png"
                                            loading="lazy"
                                            sizes="(max-width: 479px) 100vw, (max-width: 767px) 70vw, (max-width: 991px) 468px, 624px"
                                            // srcSet="images/_KOE0728-p-500.png 500w, images/_KOE0728.png 517w"
                                            alt=""
                                            className="image-bg-contain"
                                        />
                                        <div className="shift-title">
                                            <h5 className="heading armin">Armin Heinrichs</h5>
                                            <div className="div-block-293">
                                                <div className="heading-copy no-pad">
                                                    Founder of SURF Reward
                                                </div>
                                                <a
                                                    href="https://www.linkedin.com/in/armin-heinrichs-580045235/"
                                                    target="_blank"
                                                    className="social-icon-w w-inline-block"
                                                >
                                                    <img src="images/linkedin.svg" loading="lazy" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="w-node-_4d36ff4a-87c5-213f-644b-6842336758a2-052a211e">
                                        <div className="margin-bottom margin-2xl">
                                            <div className="max-width-xl">
                                                <div className="w-layout-grid row-md-2">
                                                    <img
                                                        src="images/Gruppe-43.png"
                                                        loading="lazy"
                                                        id="w-node-e8a08ecb-acd6-5113-2560-ce6352e2f3bc-052a211e"
                                                        alt=""
                                                        className="image-91"
                                                    />
                                                    <div id="w-node-_4d36ff4a-87c5-213f-644b-6842336758b1-052a211e">
                                                        <div className="margin-bottom margin-base">
                                                            <h2 className="font-title-2">
                                                                "List of benefits{" "}
                                                                <span className="text-gradient__indigo-2">
                                                                    is long
                                                                </span>
                                                                "
                                                            </h2>
                                                        </div>
                                                        <div className="text-lg _48ch">
                                                            <i>
                                                                SURF Reward makes advertising on websites
                                                                interesting again. Users no longer automatically
                                                                hide ads, but are demonstrably more interested. We
                                                                also reach audiences that often use ad blockers and
                                                                are harder to reach for certain products. Provider
                                                                restrictions can be circumvented. In many countries,
                                                                advertising on certain topics is banned, and this
                                                                opens up completely new avenues. SURF Reward is not
                                                                just a product, SURF Reward is innovation!
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="advisor bg-tile-middle-lg bg-amber-900">
                    <div className="container-xl">
                        <div className="margin-bottom margin-5xl">
                            <div className="content-centered">
                                <h2 className="title-lg">
                                    Our <span className="text-gradient__amber">Advisors</span>
                                </h2>
                            </div>
                        </div>
                        <div className="advisor-parent-w">
                            <div className="advisor-child-w">
                                <div
                                    id="w-node-_2554de13-0387-d5d2-69f1-257dc6819104-052a211e"
                                    className="fade-in-on-scroll"
                                ></div>
                                <div
                                    id="w-node-f852cf33-597e-6b0a-ac7b-772664669ef8-052a211e"
                                    className="fade-in-on-scroll"
                                >
                                    <div className="column-base">
                                        <div
                                            id="w-node-f852cf33-597e-6b0a-ac7b-772664669efa-052a211e"
                                            className="image-wrapper-1-1"
                                        >
                                            <img
                                                src="images/andreas_hinz.png"
                                                loading="lazy"
                                                alt=""
                                                className="image-background"
                                            />
                                        </div>
                                        <div
                                            id="w-node-f852cf33-597e-6b0a-ac7b-772664669efc-052a211e"
                                            className="w-layout-grid column-xxs"
                                        >
                                            <h5 id="w-node-f852cf33-597e-6b0a-ac7b-772664669efd-052a211e">
                                                Andreas Hinz
                                            </h5>
                                            <div id="w-node-f852cf33-597e-6b0a-ac7b-772664669eff-052a211e">
                                                External Advisor
                                            </div>
                                            <div
                                                id="w-node-f852cf33-597e-6b0a-ac7b-772664669f01-052a211e"
                                                className="team-socials"
                                            >
                                                <a
                                                    id="w-node-f852cf33-597e-6b0a-ac7b-772664669f02-052a211e"
                                                    href="https://de.linkedin.com/in/andreas-hinz-63a7301b2"
                                                    target="_blank"
                                                    className="social-icon-w w-inline-block"
                                                >
                                                    <i className="fab fa-linkedin" />
                                                </a>
                                                {/* <a id="w-node-f852cf33-597e-6b0a-ac7b-772664669f04-052a211e" href="#" target="_blank" class="social-icon-w w-inline-block><img src="images/instagram.svg" loading="lazy" alt="" class="social-size"></a> */}
                                                <a
                                                    id="w-node-f852cf33-597e-6b0a-ac7b-772664669f06-052a211e"
                                                    href="https://www.xing.com/profile/Andreas_Hinz54"
                                                    target="_blank"
                                                    className="social-icon-w w-inline-block"
                                                >
                                                    <i className="fab fa-xing" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b83-052a211e"
                                    className="fade-in-on-scroll"
                                >
                                    <div className="column-base">
                                        <div
                                            id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b85-052a211e"
                                            className="image-wrapper-1-1"
                                        >
                                            <img
                                                src="images/ian_scarffe_color-modified.png"
                                                loading="lazy"
                                                alt=""
                                                className="image-background"
                                            />
                                        </div>
                                        <div
                                            id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b87-052a211e"
                                            className="w-layout-grid column-xxs"
                                        >
                                            <h5 id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b88-052a211e">
                                                Ian Scarffe
                                            </h5>
                                            <div id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b8a-052a211e">
                                                Blockchain Consultant
                                            </div>
                                            <div
                                                id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b8c-052a211e"
                                                className="team-socials"
                                            >
                                                <a
                                                    id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b8d-052a211e"
                                                    href="https://uk.linkedin.com/in/ianscarffe"
                                                    className="social-icon-w w-inline-block"
                                                >
                                                    <i className="fab fa-linkedin" />
                                                </a>
                                                <a
                                                    id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b8f-052a211e"
                                                    href="https://twitter.com/IanScarffe"
                                                    className="social-icon-w w-inline-block"
                                                >
                                                    <i className="fab fa-twitter" />
                                                </a>
                                                {/* <a id="w-node-ba0a16b8-88c7-15be-e346-3b1720091b91-052a211e" href="#" class="social-icon-w w-inline-block"><img src="images/xing.svg" loading="lazy" alt="" class="social-size"></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Team