import React from 'react'

function HowItWorks() {
    return (
        <div className="section-wieso-investieren wieso-investieren">
            <div className="bg-pattern-wrap centered" />
            <div className="container-lg">
                <div className="w-layout-grid side-by-side-list">
                    <div className="w-layout-grid side-by-side-1-to-1">
                        <div id="w-node-_973dc723-8a49-e32d-11c8-b2f43fb833ea-052a211e">
                            <div className="margin-bottom margin-2xl">
                                <div className="max-width-xl">
                                    <div className="margin-bottom margin-xl">
                                        <div className="w-layout-grid caption-holder">
                                            <div className="w-layout-grid row-sm">
                                                <div className="caption opacity-50">02</div>
                                                <div className="caption">How it works</div>
                                            </div>
                                            <div
                                                id="w-node-_973dc723-8a49-e32d-11c8-b2f43fb833f4-052a211e"
                                                className="line-holder"
                                            >
                                                <div
                                                    id="w-node-_973dc723-8a49-e32d-11c8-b2f43fb833f5-052a211e"
                                                    className="line-animated"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-layout-grid row-md">
                                        <div
                                            id="w-node-_973dc723-8a49-e32d-11c8-b2f43fb833f7-052a211e"
                                            className="div-block-292"
                                        >
                                            <img
                                                src="images/Gruppe-61.svg"
                                                loading="lazy"
                                                alt=""
                                                className="image-12"
                                            />
                                        </div>
                                        <div id="w-node-_973dc723-8a49-e32d-11c8-b2f43fb833f9-052a211e">
                                            <div className="margin-bottom margin-base">
                                                <h2 className="font-title">
                                                    Why should I{" "}
                                                    <span className="text-gradient__pink">invest?</span>
                                                </h2>
                                            </div>
                                            <div className="text-lg _48ch">
                                                SURF Reward uses a large portion of its advertising revenue
                                                to fund its liquidity pool, ensuring a constant increase in
                                                the value of the SURF token. <br />
                                                <br />
                                                This makes the SURF token lucrative for everyone. Whether
                                                you are an investor, an advertising partner, a pre-sale
                                                buyer, a hodler or a user of the SURF Reward Extension.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="w-node-_973dc723-8a49-e32d-11c8-b2f43fb83427-052a211e"
                            className="image-wrapper-3-4 rounded-xs fade-in-on-scroll pink less"
                        >
                            <img
                                src="images/Fade-bg-pink.svg"
                                loading="lazy"
                                alt=""
                                className="image-background"
                            />
                            <img
                                src="images/_KOE0602.png"
                                loading="lazy"
                                sizes="(max-width: 479px) 100vw, (max-width: 767px) 70vw, (max-width: 991px) 450px, 600px"
                                srcSet="images/_KOE0602-p-500.png 500w, images/_KOE0602.png 560w"
                                alt=""
                                className="image-bg-contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HowItWorks