import React from "react";

function Roadmap() {
  return (
    <section id="roadmap">
      <div className="roadmap">
        <div className="container-lg content-centered">
          <div className="margin-bottom margin-4xl _100">
            <div className="margin-bottom margin-xl _100">
              <div className="w-layout-grid caption-holder-center">
                <div
                  id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa81b-052a211e"
                  className="line-holder invert"
                >
                  <div
                    id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa81c-052a211e"
                    className="line-animated"
                  />
                </div>
                <div className="w-layout-grid row-sm">
                  <div className="caption opacity-50">05</div>
                  <div className="caption">Roadmap</div>
                </div>
                <div
                  id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa822-052a211e"
                  className="line-holder"
                >
                  <div
                    id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa823-052a211e"
                    className="line-animated"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-sm">
          <div className="w-layout-grid process-steps">
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 3</div>
                      <div className="caption opacity-50">2019</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3d2-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3d3-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Abelius Capital AG a public limited company incorporated
                        under German law in 2019 with its registered office in
                        Düsseldorf, Germany is founded
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 2</div>
                      <div className="caption opacity-50">2021</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3d2-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3d3-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        The idea of SURF Reward is developed
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        First ideas are collated
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        A business plan and white paper are drawn up
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Market analysis &amp; strategy building begins
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 3</div>
                      <div className="caption opacity-50">2021</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3e6-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3e7-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Start of programming work
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 4</div>
                      <div className="caption opacity-50">2021</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3fb-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f3fc-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Further development
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        The project name "SURF Reward" is born
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 1</div>
                      <div className="caption opacity-50">2022</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f40f-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f410-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Start of website development
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Presentation of SURF Reward Extension demo and start of
                        development of final version
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 4</div>
                      <div className="caption opacity-50">2022</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f424-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f425-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">Token created</div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">Token audit</div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Project publication
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        First marketing steps starts
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Contract with external Blockchain Advisor Ian Scarffe
                        (Ian Scarffe is the number 1 Blockchain Advisor in the
                        Global list of Blockchain Advisors)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 1</div>
                      <div className="caption opacity-50">2023</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f40f-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f410-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        First Press Release
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Whitepaper publishing
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">Partnerships</div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">Acquisition</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div className="process-steps-indicator">
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 2</div>
                      <div className="caption opacity-50">2023</div>
                    </div>
                    <div
                      id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f40f-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_19bcaf79-1bc3-0049-5778-8039ef63f410-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">PreSale starts</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="process-steps-step">
              <div
                className="process-steps-indicator grey"
                style={{ zIndex: "1" }}
              >
                <div className="process-steps-indicator-icon">check</div>
              </div>
              <div className="process-steps-item rounded-xs fade-in-on-scroll">
                <div className="margin-bottom margin-xl">
                  <div className="w-layout-grid caption-holder">
                    <div className="w-layout-grid row-sm">
                      <div className="caption">Q 3</div>
                      <div className="caption opacity-50">2023</div>
                    </div>
                    <div
                      id="w-node-_7982a4c3-81d5-0000-98f1-a50f0fbae3cc-052a211e"
                      className="line-holder"
                    >
                      <div
                        id="w-node-_7982a4c3-81d5-0000-98f1-a50f0fbae3cd-052a211e"
                        className="line-animated"
                      ></div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90 grey"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Beta Test „SURF Reward“ Extension
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90 grey"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        SURF Token Listing
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90 grey"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Next Marketing Steps
                      </div>
                    </div>
                  </div>
                  <div className="div-block-281">
                    <img
                      src="images/Pfad-12.svg"
                      loading="lazy"
                      alt=""
                      className="image-90 grey"
                    />
                    <div className="div-block-282">
                      <div className="h-standard-text-2">
                        Start of our Self Service Advertising Platform
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="process-steps-way-bg">
                <div className="process-steps-way"></div>
              </div>
            </div>
            <div className="process-steps-way-bg">
              <div
                className="process-steps-way"
                // style={{ height: "100%" }}
                style={{ willChange: "width, height", height: "18.198%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
